/*******************************
             Theme
 *******************************/
/*

████████╗██╗  ██╗███████╗███╗   ███╗███████╗███████╗
╚══██╔══╝██║  ██║██╔════╝████╗ ████║██╔════╝██╔════╝
   ██║   ███████║█████╗  ██╔████╔██║█████╗  ███████╗
   ██║   ██╔══██║██╔══╝  ██║╚██╔╝██║██╔══╝  ╚════██║
   ██║   ██║  ██║███████╗██║ ╚═╝ ██║███████╗███████║
   ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝╚══════╝╚══════╝

*/
/*******************************
        Theme Selection
*******************************/
/* To override a theme for an individual element
   specify theme name below
*/
/* Global */
/* Elements */
/* Collections */
/* Modules */
/* Views */
/*******************************
            Folders
*******************************/
/* Path to theme packages */
/* Path to site override folder */
/*******************************
         Import Theme
*******************************/
/*******************************
        Import Directives
*******************************/
/*------------------
       Theme
-------------------*/
/*--------------------
   Site Variables
---------------------*/
/* Default site.variables */
/*******************************
         Site Settings
*******************************/
/***********************************************************
  Central element variation compilation enablers
***********************************************************/
/* General */
/*******************************
           Elements
*******************************/
/* Button */
/* Container */
/* Divider */
/* Header */
/* Icon */
/* Image */
/* Input */
/* Label */
/* List */
/* Loader */
/* Placeholder */
/* Rail */
/* Reveal */
/* Segment */
/* Step */
/* Text */
/*******************************
           Collections
*******************************/
/* Breadcrumb */
/* Form */
/* Grid */
/* Menu */
/* Message */
/* Table */
/*******************************
             Views
*******************************/
/* Ad */
/* Card */
/* Comment */
/* Feed */
/* Item */
/* Statistic */
/*******************************
            Modules
*******************************/
/* Accordion */
/* Calendar */
/* Checkbox */
/* Dimmer */
/* Dropdown */
/* Embed */
/* Modal */
/* Popup */
/* Progress */
/* Rating */
/* Search */
/* Shape */
/* Sidebar */
/* Slider */
/* Tab */
/* Toast */
/* Transition */
/* Emojis */
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/* Whenever a color needs to get calculated (screen()/multiply()) out of a base color */
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Light Colored Text ---*/
/*--- Hovered Colored Text ---*/
/*--- Colored Border ---*/
/*--- Shadows ---*/
/* Inverted */
/* Box Shadows */
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*---  Tertiary  ---*/
/*---  Bright  ---*/
/*******************************
 States shared in Form-related components
 *******************************/
/* Form state*/
/* Input state */
/* AutoFill */
/* Dropdown state */
/* Focused state */
/* Placeholder state */
/* Packaged site.variables */
/*******************************
         Site Settings
*******************************/
/***********************************************************
  Central element variation compilation enablers
***********************************************************/
/* General */
/*******************************
           Elements
*******************************/
/* Button */
/* Container */
/* Divider */
/* Header */
/* Icon */
/* Image */
/* Input */
/* Label */
/* List */
/* Loader */
/* Placeholder */
/* Rail */
/* Reveal */
/* Segment */
/* Step */
/* Text */
/*******************************
           Collections
*******************************/
/* Breadcrumb */
/* Form */
/* Grid */
/* Menu */
/* Message */
/* Table */
/*******************************
             Views
*******************************/
/* Ad */
/* Card */
/* Comment */
/* Feed */
/* Item */
/* Statistic */
/*******************************
            Modules
*******************************/
/* Accordion */
/* Calendar */
/* Checkbox */
/* Dimmer */
/* Dropdown */
/* Embed */
/* Modal */
/* Popup */
/* Progress */
/* Rating */
/* Search */
/* Shape */
/* Sidebar */
/* Slider */
/* Tab */
/* Toast */
/* Transition */
/* Emojis */
/*-------------------
       Fonts
--------------------*/
/*-------------------
      Base Sizes
--------------------*/
/* This is the single variable that controls them all */
/* The size of page text  */
/*-------------------
    Border Radius
--------------------*/
/* See Power-user section below
   for explanation of @px variables
*/
/*-------------------
    Brand Colors
--------------------*/
/* Whenever a color needs to get calculated (screen()/multiply()) out of a base color */
/*--------------
  Page Heading
---------------*/
/*--------------
   Form Input
---------------*/
/* This adjusts the default form input across all elements */
/* Input Text Color */
/* Line Height Default For Inputs in Browser (Descenders are 17px at 14px base em) */
/*-------------------
    Focused Input
--------------------*/
/* Used on inputs, textarea etc */
/* Used on dropdowns, other larger blocks */
/*-------------------
        Sizes
--------------------*/
/*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/
/*-------------------
        Page
--------------------*/
/*-------------------
      Paragraph
--------------------*/
/*-------------------
       Links
--------------------*/
/*-------------------
    Scroll Bars
--------------------*/
/* Inverted */
/*-------------------
  Highlighted Text
--------------------*/
/*-------------------
       Loader
--------------------*/
/*-------------------
        Grid
--------------------*/
/*-------------------
     Transitions
--------------------*/
/*-------------------
     Breakpoints
--------------------*/
/*-------------------
      Site Colors
--------------------*/
/*---  Colors  ---*/
/*---  Light Colors  ---*/
/*---   Neutrals  ---*/
/*--- Colored Backgrounds ---*/
/*--- Colored Headers ---*/
/*--- Colored Text ---*/
/*--- Light Colored Text ---*/
/*--- Hovered Colored Text ---*/
/*--- Colored Border ---*/
/*--- Shadows ---*/
/* Inverted */
/* Box Shadows */
/*-------------------
     Alpha Colors
--------------------*/
/*-------------------
       Accents
--------------------*/
/* Differentiating Neutrals */
/* Differentiating Layers */
/*******************************
           Power-User
*******************************/
/*-------------------
    Emotive Colors
--------------------*/
/* Positive */
/* Negative */
/* Info */
/* Warning */
/*-------------------
        Paths
--------------------*/
/* For source only. Modified in gulp for dist */
/*-------------------
       Em Sizes
--------------------*/
/*
  This rounds @size values to the closest pixel then expresses that value in (r)em.
  This ensures all size values round to exact pixels
*/
/* em */
/* rem */
/*-------------------
       Icons
--------------------*/
/* Maximum Glyph Width of Icon */
/*-------------------
     Neutral Text
--------------------*/
/*-------------------
     Brand Colors
--------------------*/
/*-------------------
      Borders
--------------------*/
/*-------------------
    Derived Values
--------------------*/
/* Loaders Position Offset */
/* Rendered Scrollbar Width */
/* Maximum Single Character Glyph Width, aka Capital "W" */
/* Used to match floats with text */
/* Header Spacing */
/* Minimum Mobile Width */
/* Positive / Negative Dupes */
/* Responsive */
/*-------------------
  Exact Pixel Values
--------------------*/
/*
  These are used to specify exact pixel values in em
  for things like borders that remain constantly
  sized as emSize adjusts

  Since there are many more sizes than names for sizes,
  these are named by their original pixel values.

*/
/* Columns */
/*******************************
             States
*******************************/
/*-------------------
      Disabled
--------------------*/
/*-------------------
        Hover
--------------------*/
/*---  Shadows  ---*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Focus
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
    Down (:active)
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*-------------------
        Active
--------------------*/
/*---  Colors  ---*/
/*---  Emotive  ---*/
/*---  Brand   ---*/
/*---  Dark Tones  ---*/
/*---  Light Tones  ---*/
/*---  Tertiary  ---*/
/*---  Bright  ---*/
/*******************************
 States shared in Form-related components
 *******************************/
/* Form state*/
/* Input state */
/* AutoFill */
/* Dropdown state */
/* Focused state */
/* Placeholder state */
/* Component's site.variables */
/* Site theme site.variables */
/*******************************
     User Global Variables
*******************************/
/*-------------------
 Component Variables
---------------------*/
/* Default */
/*******************************
        Slider Variables
*******************************/
/*-------------------
       Element
--------------------*/
/* Track */
/* Track Fill */
/* Thumb */
/* Thumb Hover */
/*-------------------
        States
--------------------*/
/* Disabled */
/*-------------------
      Variations
--------------------*/
/* Vertical */
/* Labeled */
/* Hover */
/* Sizing */
/* Packaged Theme */
/*******************************
        Slider Variables
*******************************/
/*-------------------
       Element
--------------------*/
/* Track */
/* Track Fill */
/* Thumb */
/* Thumb Hover */
/*-------------------
        States
--------------------*/
/* Disabled */
/*-------------------
      Variations
--------------------*/
/* Vertical */
/* Labeled */
/* Hover */
/* Sizing */
/* Site Theme */
/*-------------------------
    Central Color Map
-------------------------*/
/* Default */
/***********************************************************
  Central Color Mapping Base for all components to iterate
***********************************************************/
/* Site Theme */
/***********************************************************
  Central Color Mapping Base for all components to iterate
***********************************************************/
/*******************************
             Mix-ins
*******************************/
/*------------------
       Fonts
-------------------*/
/*------------------
     Overrides
-------------------*/
/* End Config */
.ui.slider:not(.vertical):not(.checkbox) {
  width: 100%;
  padding: 1em 0.5em;
}
.ui.slider:not(.checkbox) {
  position: relative;
}
.ui.slider:not(.checkbox):focus {
  outline: 0;
}
.ui.slider .inner {
  position: relative;
  z-index: 2;
}
.ui.slider:not(.vertical) .inner {
  height: 1.5em;
}
.ui.slider .inner:hover {
  cursor: auto;
}
.ui.slider .inner .track {
  position: absolute;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
}
.ui.slider:not(.vertical) .inner .track {
  width: 100%;
  height: 0.4em;
  top: 0.55em;
  left: 0;
}
.ui.slider .inner .track-fill {
  position: absolute;
  border-radius: 4px;
  background-color: #1B1C1D;
}
.ui.slider:not(.vertical) .inner .track-fill {
  height: 0.4em;
  top: 0.55em;
  left: 0;
}
.ui.slider .inner .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 1.5em;
  width: 1.5em;
  background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  border-radius: 100%;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15), 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
  transition: background 0.3s ease;
}
.ui.slider:not(.disabled) .inner .thumb:hover {
  cursor: pointer;
  background: #f2f2f2 linear-gradient(transparent, rgba(0, 0, 0, 0.05));
}
.ui.slider:not(.disabled):focus .inner .thumb {
  background: #f2f2f2 linear-gradient(transparent, rgba(0, 0, 0, 0.05));
}
/*******************************
            States
*******************************/
/*--------------
       Disabled
  ---------------*/
.ui.disabled.slider:not(.checkbox) {
  opacity: 0.5;
}
.ui.disabled.slider .inner:hover {
  cursor: auto;
}
.ui.disabled.slider .inner .track-fill {
  background: #ccc;
}
/*--------------
      Reversed
  ---------------*/
.ui.reversed.slider .inner .track-fill {
  left: auto;
  right: 0;
}
.ui.reversed.slider:not(.vertical) .inner .thumb {
  left: auto;
  right: 0;
}
.ui.reversed.vertical.slider .inner .thumb {
  left: 0.03em;
}
.ui.labeled.reversed.slider > .labels .label {
  transform: translate(-100%, -100%);
}
/*******************************
           Variations
*******************************/
/*--------------
      Vertical
  ---------------*/
.ui.vertical.slider {
  height: 100%;
  width: 1.5em;
  padding: 0.5em 1em;
}
.ui.vertical.slider .inner {
  height: 100%;
}
.ui.vertical.slider .inner .track {
  height: 100%;
  width: 0.4em;
  left: 0.55em;
  top: 0;
}
.ui.vertical.slider .inner .track-fill {
  width: 0.4em;
  left: 0.55em;
  top: 0;
}
/* Vertical Reversed */
.ui.vertical.reversed.slider .inner .thumb {
  top: auto;
  bottom: 0;
}
.ui.vertical.reversed.slider .inner .track-fill {
  top: auto;
  bottom: 0;
}
/*--------------
      Labeled
  ---------------*/
.ui.labeled.slider > .labels {
  height: 1.5em;
  width: auto;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.ui.labeled.slider:not(.vertical) > .labels {
  transform: translateY(-50%);
}
.ui.labeled.slider > .labels .label {
  display: inline-flex;
  padding: 0.2em 0;
  position: absolute;
  transform: translate(-50%, -100%);
  white-space: nowrap;
}
.ui.bottom.aligned.labeled.slider > .labels .label {
  transform: translate(-50%, 100%);
}
.ui.labeled.ticked.slider > .labels .label:after {
  content: ' ';
  height: 1.5em;
  width: 1px;
  background: #ccc;
  position: absolute;
  top: 100%;
  left: 50%;
}
.ui.bottom.aligned.labeled.ticked.slider > .labels .label:after {
  top: -100%;
}
.ui.labeled.ticked.slider > .labels .halftick.label:after {
  height: 0.75em;
}
/* Vertical Labels */
.ui.labeled.vertical.slider > .labels {
  width: 1.5em;
  height: auto;
  left: 50%;
  top: 0;
  bottom: 0;
  transform: translateX(-50%);
}
.ui.labeled.vertical.slider > .labels .label {
  transform: translate(-100%, -50%);
}
.ui.labeled.vertical.slider > .labels .label:after {
  width: 1.5em;
  height: 1px;
  left: 100%;
  top: 50%;
}
.ui.labeled.vertical.slider > .labels .halftick.label:after {
  width: 0.75em;
  height: 1px;
}
/* Vertical Reversed Labels */
.ui.labeled.vertical.reversed.slider > .labels .label {
  transform: translate(-100%, 50%);
}
/*--------------
    Hover
---------------*/
.ui.hover.slider .inner .thumb {
  opacity: 0;
  transition: opacity 0.2s linear;
}
.ui.hover.slider:not(.disabled):hover .inner .thumb,
.ui.hover.slider:not(.disabled):focus .inner .thumb {
  opacity: 1;
}
/*--------------
      Inverted
  ---------------*/
.ui.inverted.slider .inner .track-fill {
  background-color: #545454;
}
.ui.inverted.slider .inner .track {
  background-color: rgba(255, 255, 255, 0.08);
}
/*--------------
     Colors
---------------*/
/* Standard */
.ui.primary.slider .inner .track-fill {
  background-color: #00579E;
}
.ui.primary.inverted.slider .inner .track-fill {
  background-color: #54C8FF;
}
/* Basic */
.ui.primary.slider.basic .inner .thumb {
  background-color: #00579E;
}
.ui.primary.slider.basic .inner .thumb:hover,
.ui.primary.slider.basic:focus .inner .thumb {
  background-color: #004985;
}
/* Basic Inverted */
.ui.primary.inverted.slider.basic .inner .thumb {
  background-color: #54C8FF;
}
.ui.primary.inverted.slider.basic .inner .thumb:hover,
.ui.primary.inverted.slider.basic:focus .inner .thumb {
  background-color: #21b8ff;
}
/* Standard */
.ui.secondary.slider .inner .track-fill {
  background-color: #1B1C1D;
}
.ui.secondary.inverted.slider .inner .track-fill {
  background-color: #545454;
}
/* Basic */
.ui.secondary.slider.basic .inner .thumb {
  background-color: #1B1C1D;
}
.ui.secondary.slider.basic .inner .thumb:hover,
.ui.secondary.slider.basic:focus .inner .thumb {
  background-color: #27292a;
}
/* Basic Inverted */
.ui.secondary.inverted.slider.basic .inner .thumb {
  background-color: #545454;
}
.ui.secondary.inverted.slider.basic .inner .thumb:hover,
.ui.secondary.inverted.slider.basic:focus .inner .thumb {
  background-color: #6e6e6e;
}
/* Standard */
.ui.red.slider .inner .track-fill {
  background-color: #DB2828;
}
.ui.red.inverted.slider .inner .track-fill {
  background-color: #FF695E;
}
/* Basic */
.ui.red.slider.basic .inner .thumb {
  background-color: #DB2828;
}
.ui.red.slider.basic .inner .thumb:hover,
.ui.red.slider.basic:focus .inner .thumb {
  background-color: #d01919;
}
/* Basic Inverted */
.ui.red.inverted.slider.basic .inner .thumb {
  background-color: #FF695E;
}
.ui.red.inverted.slider.basic .inner .thumb:hover,
.ui.red.inverted.slider.basic:focus .inner .thumb {
  background-color: #ff392b;
}
/* Standard */
.ui.orange.slider .inner .track-fill {
  background-color: #F2711C;
}
.ui.orange.inverted.slider .inner .track-fill {
  background-color: #FF851B;
}
/* Basic */
.ui.orange.slider.basic .inner .thumb {
  background-color: #F2711C;
}
.ui.orange.slider.basic .inner .thumb:hover,
.ui.orange.slider.basic:focus .inner .thumb {
  background-color: #f26202;
}
/* Basic Inverted */
.ui.orange.inverted.slider.basic .inner .thumb {
  background-color: #FF851B;
}
.ui.orange.inverted.slider.basic .inner .thumb:hover,
.ui.orange.inverted.slider.basic:focus .inner .thumb {
  background-color: #e76b00;
}
/* Standard */
.ui.yellow.slider .inner .track-fill {
  background-color: #FBBD08;
}
.ui.yellow.inverted.slider .inner .track-fill {
  background-color: #FFE21F;
}
/* Basic */
.ui.yellow.slider.basic .inner .thumb {
  background-color: #FBBD08;
}
.ui.yellow.slider.basic .inner .thumb:hover,
.ui.yellow.slider.basic:focus .inner .thumb {
  background-color: #eaae00;
}
/* Basic Inverted */
.ui.yellow.inverted.slider.basic .inner .thumb {
  background-color: #FFE21F;
}
.ui.yellow.inverted.slider.basic .inner .thumb:hover,
.ui.yellow.inverted.slider.basic:focus .inner .thumb {
  background-color: #ebcd00;
}
/* Standard */
.ui.olive.slider .inner .track-fill {
  background-color: #B5CC18;
}
.ui.olive.inverted.slider .inner .track-fill {
  background-color: #D9E778;
}
/* Basic */
.ui.olive.slider.basic .inner .thumb {
  background-color: #B5CC18;
}
.ui.olive.slider.basic .inner .thumb:hover,
.ui.olive.slider.basic:focus .inner .thumb {
  background-color: #a7bd0d;
}
/* Basic Inverted */
.ui.olive.inverted.slider.basic .inner .thumb {
  background-color: #D9E778;
}
.ui.olive.inverted.slider.basic .inner .thumb:hover,
.ui.olive.inverted.slider.basic:focus .inner .thumb {
  background-color: #d2e745;
}
/* Standard */
.ui.green.slider .inner .track-fill {
  background-color: #21BA45;
}
.ui.green.inverted.slider .inner .track-fill {
  background-color: #2ECC40;
}
/* Basic */
.ui.green.slider.basic .inner .thumb {
  background-color: #21BA45;
}
.ui.green.slider.basic .inner .thumb:hover,
.ui.green.slider.basic:focus .inner .thumb {
  background-color: #16ab39;
}
/* Basic Inverted */
.ui.green.inverted.slider.basic .inner .thumb {
  background-color: #2ECC40;
}
.ui.green.inverted.slider.basic .inner .thumb:hover,
.ui.green.inverted.slider.basic:focus .inner .thumb {
  background-color: #1ea92e;
}
/* Standard */
.ui.teal.slider .inner .track-fill {
  background-color: #00B5AD;
}
.ui.teal.inverted.slider .inner .track-fill {
  background-color: #6DFFFF;
}
/* Basic */
.ui.teal.slider.basic .inner .thumb {
  background-color: #00B5AD;
}
.ui.teal.slider.basic .inner .thumb:hover,
.ui.teal.slider.basic:focus .inner .thumb {
  background-color: #009c95;
}
/* Basic Inverted */
.ui.teal.inverted.slider.basic .inner .thumb {
  background-color: #6DFFFF;
}
.ui.teal.inverted.slider.basic .inner .thumb:hover,
.ui.teal.inverted.slider.basic:focus .inner .thumb {
  background-color: #3affff;
}
/* Standard */
.ui.blue.slider .inner .track-fill {
  background-color: #00579E;
}
.ui.blue.inverted.slider .inner .track-fill {
  background-color: #54C8FF;
}
/* Basic */
.ui.blue.slider.basic .inner .thumb {
  background-color: #00579E;
}
.ui.blue.slider.basic .inner .thumb:hover,
.ui.blue.slider.basic:focus .inner .thumb {
  background-color: #004985;
}
/* Basic Inverted */
.ui.blue.inverted.slider.basic .inner .thumb {
  background-color: #54C8FF;
}
.ui.blue.inverted.slider.basic .inner .thumb:hover,
.ui.blue.inverted.slider.basic:focus .inner .thumb {
  background-color: #21b8ff;
}
/* Standard */
.ui.violet.slider .inner .track-fill {
  background-color: #6435C9;
}
.ui.violet.inverted.slider .inner .track-fill {
  background-color: #A291FB;
}
/* Basic */
.ui.violet.slider.basic .inner .thumb {
  background-color: #6435C9;
}
.ui.violet.slider.basic .inner .thumb:hover,
.ui.violet.slider.basic:focus .inner .thumb {
  background-color: #5829bb;
}
/* Basic Inverted */
.ui.violet.inverted.slider.basic .inner .thumb {
  background-color: #A291FB;
}
.ui.violet.inverted.slider.basic .inner .thumb:hover,
.ui.violet.inverted.slider.basic:focus .inner .thumb {
  background-color: #745aff;
}
/* Standard */
.ui.purple.slider .inner .track-fill {
  background-color: #A333C8;
}
.ui.purple.inverted.slider .inner .track-fill {
  background-color: #DC73FF;
}
/* Basic */
.ui.purple.slider.basic .inner .thumb {
  background-color: #A333C8;
}
.ui.purple.slider.basic .inner .thumb:hover,
.ui.purple.slider.basic:focus .inner .thumb {
  background-color: #9627ba;
}
/* Basic Inverted */
.ui.purple.inverted.slider.basic .inner .thumb {
  background-color: #DC73FF;
}
.ui.purple.inverted.slider.basic .inner .thumb:hover,
.ui.purple.inverted.slider.basic:focus .inner .thumb {
  background-color: #cf40ff;
}
/* Standard */
.ui.pink.slider .inner .track-fill {
  background-color: #E03997;
}
.ui.pink.inverted.slider .inner .track-fill {
  background-color: #FF8EDF;
}
/* Basic */
.ui.pink.slider.basic .inner .thumb {
  background-color: #E03997;
}
.ui.pink.slider.basic .inner .thumb:hover,
.ui.pink.slider.basic:focus .inner .thumb {
  background-color: #e61a8d;
}
/* Basic Inverted */
.ui.pink.inverted.slider.basic .inner .thumb {
  background-color: #FF8EDF;
}
.ui.pink.inverted.slider.basic .inner .thumb:hover,
.ui.pink.inverted.slider.basic:focus .inner .thumb {
  background-color: #ff5bd1;
}
/* Standard */
.ui.brown.slider .inner .track-fill {
  background-color: #A5673F;
}
.ui.brown.inverted.slider .inner .track-fill {
  background-color: #D67C1C;
}
/* Basic */
.ui.brown.slider.basic .inner .thumb {
  background-color: #A5673F;
}
.ui.brown.slider.basic .inner .thumb:hover,
.ui.brown.slider.basic:focus .inner .thumb {
  background-color: #975b33;
}
/* Basic Inverted */
.ui.brown.inverted.slider.basic .inner .thumb {
  background-color: #D67C1C;
}
.ui.brown.inverted.slider.basic .inner .thumb:hover,
.ui.brown.inverted.slider.basic:focus .inner .thumb {
  background-color: #b0620f;
}
/* Standard */
.ui.grey.slider .inner .track-fill {
  background-color: #767676;
}
.ui.grey.inverted.slider .inner .track-fill {
  background-color: #DCDDDE;
}
/* Basic */
.ui.grey.slider.basic .inner .thumb {
  background-color: #767676;
}
.ui.grey.slider.basic .inner .thumb:hover,
.ui.grey.slider.basic:focus .inner .thumb {
  background-color: #838383;
}
/* Basic Inverted */
.ui.grey.inverted.slider.basic .inner .thumb {
  background-color: #DCDDDE;
}
.ui.grey.inverted.slider.basic .inner .thumb:hover,
.ui.grey.inverted.slider.basic:focus .inner .thumb {
  background-color: #c2c4c5;
}
/* Standard */
.ui.black.slider .inner .track-fill {
  background-color: #1B1C1D;
}
.ui.black.inverted.slider .inner .track-fill {
  background-color: #545454;
}
/* Basic */
.ui.black.slider.basic .inner .thumb {
  background-color: #1B1C1D;
}
.ui.black.slider.basic .inner .thumb:hover,
.ui.black.slider.basic:focus .inner .thumb {
  background-color: #27292a;
}
/* Basic Inverted */
.ui.black.inverted.slider.basic .inner .thumb {
  background-color: #545454;
}
.ui.black.inverted.slider.basic .inner .thumb:hover,
.ui.black.inverted.slider.basic:focus .inner .thumb {
  background-color: #000000;
}
/*--------------
       Basic
  ---------------*/
/* Standard */
.ui.slider.basic .inner .thumb {
  background-color: #1B1C1D;
}
.ui.slider.basic .inner .thumb:hover,
.ui.slider.basic:focus .inner .thumb {
  background-color: #27292a;
}
/*--------------
      Basic Inverted
    ---------------*/
/* Standard */
.ui.inverted.slider.basic .inner .thumb {
  background-color: #545454;
}
.ui.inverted.slider.basic .inner .thumb:hover,
.ui.inverted.slider.basic:focus .inner .thumb {
  background-color: #000000;
}
/*--------------
     Sizing
---------------*/
.ui.slider.small .inner .thumb {
  height: 1em;
  width: 1em;
}
.ui.slider.small:not(.vertical) .inner {
  height: 1em;
}
.ui.slider.small:not(.vertical) .inner .track,
.ui.slider.small:not(.vertical) .inner .track-fill {
  height: 0.3em;
  top: 0.35em;
}
.ui.small.labeled.slider:not(.vertical) > .labels,
.ui.small.labeled.slider:not(.vertical) > .labels .label:after {
  height: 1em;
}
.ui.small.labeled.slider:not(.vertical) > .labels .halftick.label:after {
  height: 0.5em;
}
/* Small Vertical */
.ui.slider.small.vertical .inner {
  width: 1em;
}
.ui.slider.small.vertical .inner .track,
.ui.slider.small.vertical .inner .track-fill {
  width: 0.3em;
  left: 0.35em;
}
.ui.small.labeled.vertical.slider > .labels,
.ui.small.labeled.vertical.slider > .labels .label:after {
  width: 1em;
}
.ui.small.labeled.vertical.slider > .labels .halftick.label:after {
  width: 0.5em;
}
.ui.slider.large .inner .thumb {
  height: 2em;
  width: 2em;
}
.ui.slider.large:not(.vertical) .inner {
  height: 2em;
}
.ui.slider.large:not(.vertical) .inner .track,
.ui.slider.large:not(.vertical) .inner .track-fill {
  height: 0.5em;
  top: 0.75em;
}
.ui.large.labeled.slider:not(.vertical) > .labels,
.ui.large.labeled.slider:not(.vertical) > .labels .label:after {
  height: 2em;
}
.ui.large.labeled.slider:not(.vertical) > .labels .halftick.label:after {
  height: 1em;
}
/* Small Vertical */
.ui.slider.large.vertical .inner {
  width: 2em;
}
.ui.slider.large.vertical .inner .track,
.ui.slider.large.vertical .inner .track-fill {
  width: 0.5em;
  left: 0.75em;
}
.ui.large.labeled.vertical.slider > .labels,
.ui.large.labeled.vertical.slider > .labels .label:after {
  width: 2em;
}
.ui.large.labeled.vertical.slider > .labels .halftick.label:after {
  width: 1em;
}
.ui.slider.big .inner .thumb {
  height: 2.5em;
  width: 2.5em;
}
.ui.slider.big:not(.vertical) .inner {
  height: 2.5em;
}
.ui.slider.big:not(.vertical) .inner .track,
.ui.slider.big:not(.vertical) .inner .track-fill {
  height: 0.6em;
  top: 0.95em;
}
.ui.big.labeled.slider:not(.vertical) > .labels,
.ui.big.labeled.slider:not(.vertical) > .labels .label:after {
  height: 2.5em;
}
.ui.big.labeled.slider:not(.vertical) > .labels .halftick.label:after {
  height: 1.25em;
}
/* Small Vertical */
.ui.slider.big.vertical .inner {
  width: 2.5em;
}
.ui.slider.big.vertical .inner .track,
.ui.slider.big.vertical .inner .track-fill {
  width: 0.6em;
  left: 0.95em;
}
.ui.big.labeled.vertical.slider > .labels,
.ui.big.labeled.vertical.slider > .labels .label:after {
  width: 2.5em;
}
.ui.big.labeled.vertical.slider > .labels .halftick.label:after {
  width: 1.25em;
}
/*******************************
        Slider Overrides
*******************************/
